import { type NavigationsAndGlobalsQuery } from '#gql'
import type { Schemas } from '#shopware'

export const CMS_GLOBALS = Symbol('cmsGlobals') as InjectionKey<
  Ref<Enforce<NavigationsAndGlobalsQuery>>
>
export const SHOP_GLOBALS = Symbol('shopGlobals') as InjectionKey<
  Ref<Schemas['NavigationRouteResponse']>
>

export const useInitGlobals = async () => {
  const { locale, localeProperties } = useI18n()
  const { apiClient } = useShopwareContext()
  const { changeLanguage } = useInternationalization()
  const { loadNavigationElements } = useNavigation()
  const { refreshSessionContext } = useSessionContext()

  apiClient.defaultHeaders.apply({
    'sw-language-id': localeProperties.value.languageId,
  })

  const loadGlobals = async () => {
    // don't use asyncGQL
    const { data } = await useAsyncGql({
      operation: 'NavigationsAndGlobals',
      variables: { lang: locale },
    })
    return data.value
  }

  const loadNavigation = async () => {
    return await loadNavigationElements({ depth: 4 })
  }

  // use async data to fetch on server
  const ssrCms = useAsyncData('globals', loadGlobals)
  const ssrShop = useAsyncData('shopNavigation', loadNavigation)

  try {
    await Promise.all([ssrCms, ssrShop])
  } catch (e) {
    console.error(e)
    throw createError({
      statusCode: 500,
      message: "Couldn't load globals",
    })
  }

  // wrap in ref to allow locale change
  const globalsCms = ref(ssrCms.data.value)
  const globalsShop = ref(ssrShop.data.value)

  watch(locale, async () => {
    const languageId = localeProperties.value.languageId
    await changeLanguage(languageId)
    apiClient.defaultHeaders.apply({ 'sw-language-id': languageId })

    const [cmsData, shopData] = await Promise.all([
      loadGlobals(),
      loadNavigation(),
      refreshSessionContext(),
    ])

    globalsCms.value = cmsData
    globalsShop.value = shopData
  })

  return {
    globalsCms,
    globalsShop,
  }
}
