<template>
  <NuxtLayout>
    <Container>
      <div class="mx-auto max-w-screen-lg py-52">
        <h2 class="text-3xl font-semibold">
          {{ $t(`error.${error.statusCode}.title`) }}
        </h2>
        <div class="my-8">
          {{ $t(`error.${error.statusCode}.text`) }}
        </div>
        <AppButton size="sm" @click="clearError({ redirect: '/' + locale })">
          {{ $t('general.backToFrontPage') }}
        </AppButton>
        <div class="relative my-4 overflow-x-scroll">
          <div class="bg-red-700 px-4 py-2 text-white">
            {{ error.message }}
          </div>
          <pre class="pt-4" v-html="error.stack" />
        </div>
      </div>
    </Container>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: any
  }
}>()

const runtimeConfig = useRuntimeConfig()
const { getWishlistProducts } = useWishlist()
const { refreshCart } = useCart()
const { t, locale } = useI18n()

try {
  const { globalsCms, globalsShop } = await useInitGlobals()
  provide(CMS_GLOBALS, globalsCms)
  provide(SHOP_GLOBALS, globalsShop)
} catch (e) {
  console.error('Error during global initialization:', e)
}

// Shopware prefix
provide('urlPrefix', runtimeConfig.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  title: t(`error.${props.error.statusCode}.title`),
  titleTemplate: (title) =>
    title
      ? `${import.meta.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${import.meta.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

onMounted(() => {
  getWishlistProducts()
  refreshCart()
})
</script>
