<template>
  <NuxtLoadingIndicator color="black" :throttle="0" :height="6" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
const { getWishlistProducts } = useWishlist()
const { refreshCart } = useCart()

const { globalsCms, globalsShop } = await useInitGlobals()
provide(CMS_GLOBALS, globalsCms)
provide(SHOP_GLOBALS, globalsShop)

// Shopware prefix
provide('urlPrefix', runtimeConfig.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  titleTemplate: (title) =>
    title
      ? `${import.meta.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${import.meta.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

onMounted(() => {
  getWishlistProducts()
  refreshCart()
})
</script>
